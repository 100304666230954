a:active,
a:hover {
  color: #e59285;
}

mark {
  background: #e59285;
  color: #000; }

.theme-color {
  color: #e59285; }

a#scrollUp {
  background: #e59285;
}
a#scrollUp:hover {
  color: #e59285; }

a:hover {
  color: #e59285; }

/* Define Colors */
.color--theme {
  color: #e59285; }

/* Background Color */
.bg--theme {
  background: #e59285; }

[data-overlay]:before {
  background-color: #e59285; }

.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #e59285;
  border-color:#e59285;}

.social__net.social__net--2 li a:hover {
  border-color: #e59285;
  color: #e59285; }

.social__net.social__net--3 li a:hover {
  border-color: #e59285;
  color: #e59285; }

.social__net--4 li a:hover {
  color: #e59285; }

.block-bg .close__wrap:hover {
  color: #e59285; }

.box-menu-content .ppnav .ppmainmenu li:hover a {
  color: #e59285; }
.box-menu-content .ppnav .ppmainmenu li.drop .ppmegamenu .item li a:hover {
  color: #e59285; }
.minicart-content-wrapper .micart__close:hover {
  color: #e59285; }
.minicart-content-wrapper .mini_action .checkout__btn:hover {
  background: #e59285 none repeat scroll 0 0;
}
.minicart-content-wrapper .mini_action .cart__btn:hover {
  background-color: #e59285;
  border-color: #e59285;
}
.minicart-content-wrapper .single__items .miniproduct .item01 .content h6 a:hover {
  color: #e59285; }

.minicart-content-wrapper .single__items .miniproduct .item01 .content .product_prize ul li i:hover {
  color: #e59285; }

.mainmenu__nav .meninmenu li a:hover{color: #e59285;}

.meninmenu li.drop .megamenu .item li a:hover {
  color: #e59285;
}
.switcher__account > ul > li:hover a {
  color: #e59285; }
.switcher__account > ul > li.drop .dropdown li a:hover {
  color: #e59285; }

.testimonial__container .slick-arrow:hover {
  background: #e59285;
  border-color:#e59285;
}
.product .product__content .action .actions_inner .add_to_links li a.quickview:hover {
  background-color: #e59285; }
.rating li.on i {
  color: #e59285; }

.product__nav a:hover, .product__nav a.active {
  color: #e59285;
  transition: all 300ms ease-in 0s; }

.product__nav a.item__before::before {
  background: #e59285 none repeat scroll 0 0;
}
.s-price-box span.new-price {
  color: #e59285; }
.addtocart-btn a:hover {
  background: #e59285;
}
.newsletter__block .newsletter__box button:hover {
  color: #e59285; }

.slide .slider__content h1 span {
  color: #e59285; }
.slide .slider__content a.shopbtn:hover {
  color: #e59285; }
.slide__arrow01.owl-theme .owl-nav [class*="owl-"]:hover {
  color: #e59285;
  border: 1px solid #e59285; }
.wenro__blog .content h2 a:hover {
  color: #e59285; }
.wenro__blog .content .blog__btn:hover {
  border-color: #e59285;
  color: #e59285; }
.blog-page .blog__post .content h4 a:hover {
  color: #e59285; }
.blog-page .blog__post .content .blog__btn a:hover {
  color: #e59285; }
.post-meta ul li a i {
  color:#e59285;
}
.bradcaump__inner .bradcaump-content .breadcrumb_item.active, .bradcaump__inner .bradcaump-content .breadcrumb_item:hover {
  color: #e59285; }

.product__info__main .product-info-stock-sku p span {
  color: #e59285; }

.product__info__main .product-reviews-summary .rating-summary li i {
  color: #e59285; }

.product__info__main .product-reviews-summary .reviews-actions a:hover {
  color: #e59285; }

.product__info__main .box-tocart .addtocart__actions .tocart:hover {
  background-color: #e59285;
  border-color: #e59285; }
.product__info__main .product-addto-links a:hover {
  background-color: #e59285;
  border-color: #e59285; }
.wn__sidebar .widget.recent_widget .recent-posts ul li .post-wrapper .content h4 a:hover {
  color: #e59285; }
.wn__sidebar .widget.comment_widget ul li .post-wrapper .content a:hover {
  color: #e59285; }
.wn__sidebar .widget.category_widget ul li a:hover {
  color: #e59285; }
.wn__sidebar .widget.archives_widget ul li a:hover {
  color: #e59285; }

.blog-details .comments_area .comment__title::after {
  background: #e59285 none repeat scroll 0 0; }
.blog-details .comments_area .comment__list li .wn__comment .content .comnt__author span a {
  color: #e59285; }
.blog-details .comments_area .comment__list li .wn__comment .content .comnt__author .reply__btn a {
  color: #e59285;}
.blog-details .comment_respond h3.reply_title::after {
  background: #e59285 none repeat scroll 0 0;}
.blog-details .comment_respond h3.reply_title small a {
  color: #e59285; }
.blog-details .comment_respond .comment__form .submite__btn a:hover {
  border-color: #e59285;
  color: #e59285; }
.contact-form-wrap .contact-btn button:hover {
  border-color: #e59285;
  color: #e59285; }

.account__form .input__box label span {
  color: #e59285; }
.account__form .form__btn button:hover {
  border-color: #e59285;
  color: #e59285; }
.account__form .forget_pass {
  color: #e59285;}
.table-content table td.product-name a:hover {
  color: #e59285; }
.cart__btn__list li a:hover {
  background: #e59285 none repeat scroll 0 0; }
.cart__total__amount {
  background: #e59285 none repeat scroll 0 0; }

.product-stock-status span.wishlist-in-stock {
  color: #e59285; }
.product-add-to-cart a:hover {
  border: 2px solid #e59285;
  color: #e59285; }

.checkout_info {
  border-top: 3px solid #e59285;
  border-top-color: #e59285; }
.checkout_info::before {
  color: #e59285; }

.checkout_info a.showlogin, .checkout_info a.showcoupon {
  color: #e59285; }
.wn__checkout__form .form__btn button:hover {
  border-color: #e59285;
  color: #e59285; }
.wn__checkout__form .form__btn .label-for-checkbox a {
  color: #e59285; }
.checkout_coupon .form__coupon button:hover {
  color: #e59285; }
.wedget__categories.poroduct--cat ul li a:hover {
  color: #e59285; }
.wedget__categories.sidebar--banner .text h2 {
  color: #e59285; }
.wedget__categories.sidebar--banner .text h6 strong {
  color: #e59285; }
.list__view .content .cart__action li.wishlist a:hover {
  border: 2px solid #e59285;
  background-color: #e59285; }

.list__view .content .cart__action li.compare a:hover {
  border: 2px solid #e59285;
  background-color: #e59285; }
.footer__menu .footer__content .mainmenu li a:hover {
  color: #e59285; }

.quick-btn:hover {
  background: #e59285 none repeat scroll 0 0;
}

.section__title h2 span {
  color: #e59285;
}

.bradcaump-content li span {
  color: #e59285;
}

.wedget__categories.poroduct--cat ul li.active a {
  color: #e59285;
}

.list__view .content .cart__action li.quickview a:hover {
  border: 2px solid #e59285;
  background-color: #e59285;
}

.swatch-element input:checked + label {
  color: #e59285;
}
.swatch-element > label:hover {
  color: #e59285;
}

.swatch-element.color input:checked + label, .swatch-element.color label:hover {
  outline: 2px solid #e59285;
}

.product__info__main .product-addto-links a:hover, .product__info__main .product-addto-links a.is-active {
  background-color: #e59285;
  border-color: #e59285;
}
.product__info__main .product-addto-links a:hover, .product__info__main .product-addto-links a.is-active {
  background-color: #e59285;
  border-color: #e59285;
}

.ajax-popup .modal-footer a:hover {
  background: #e59285;
  border-color: #e59285
}

.cart__btn__list li input:hover {
  background: #e59285 none repeat scroll 0 0;
}

.blog-details .comment_respond .comment__form .submite__btn button:hover {
  border-color: #e59285;
  color: #e59285;
}

.mainmenu__nav .meninmenu li.drop .megamenu.normal-dropdown a.item:hover {
  color: #e59285;
}
.product .product__content .action .actions_inner .add_to_links li a.is-active {
  background: #e59285;
}

.slider--15 .slide .slider__content h2 span {
  color: #e59285;
}
.contentbox > h3 {
  color: #e59285;
}
.product .product__thumb .hot__box {
  background: #e59285 none repeat scroll 0 0;
}
.product .product__thumb .hot__box::after {
  border-color: transparent transparent transparent #e59285;
}
.product.product__style--3 .product__content .prize li {
  color: #e59285;
}
.product .product__content .action .actions_inner .add_to_links li a:hover {
	background-color: #e59285;
}
.header__area .header__sidebar__right > li.shopcart > a span.product_qun {
	background: #e59285;
}

.spr-starrating.spr-badge-starrating i {
	color: #e59285;
}
.brown--color .owl-theme .owl-nav [class*="owl-"]:hover {
	color: #e59285;
}